import { Icon } from '@app/models/commons.model';

export const icons: Icon[] = [
  { name: 'add', path: 'assets/icons/add.svg' },
  { name: 'admin', path: 'assets/icons/admin.svg' },
  { name: 'arrow-left', path: 'assets/icons/arrow-left.svg' },
  { name: 'arrow-right', path: 'assets/icons/arrow-right.svg' },
  { name: 'cancel', path: 'assets/icons/cancel.svg' },
  { name: 'cancel-alternative', path: 'assets/icons/cancel-alternative.svg' },
  { name: 'check', path: 'assets/icons/check.svg' },
  { name: 'company', path: 'assets/icons/company.svg' },
  { name: 'copy', path: 'assets/icons/copy.svg' },
  { name: 'delete', path: 'assets/icons/delete.svg' },
  { name: 'documents', path: 'assets/icons/documents.svg' },
  { name: 'download', path: 'assets/icons/download.svg' },
  { name: 'driver', path: 'assets/icons/driver.svg' },
  { name: 'edit', path: 'assets/icons/edit.svg' },
  { name: 'generate_pin', path: 'assets/icons/generate_pin.svg' },
  { name: 'logout', path: 'assets/icons/logout.svg' },
  { name: 'map', path: 'assets/icons/map.svg' },
  { name: 'notify', path: 'assets/icons/notify.svg' },
  { name: 'resend', path: 'assets/icons/resend.svg' },
  { name: 'order', path: 'assets/icons/order.svg' },
  { name: 'send', path: 'assets/icons/send.svg' },
  { name: 'share', path: 'assets/icons/share.svg' },
  { name: 'trailer', path: 'assets/icons/trailer.svg' },
  { name: 'trailer-truck', path: 'assets/icons/trailer-truck.svg' },
  { name: 'truck', path: 'assets/icons/truck.svg' },
  { name: 'unshare', path: 'assets/icons/unshare.svg' },
  { name: 'worker', path: 'assets/icons/worker.svg' },
  { name: 'assign', path: 'assets/icons/add.svg' },
  { name: 'unassign', path: 'assets/icons/cancel-alternative.svg' },
  { name: 'entrust', path: 'assets/icons/add.svg' },
  { name: 'distrust', path: 'assets/icons/cancel-alternative.svg' },
];

import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () =>
      import('./views/auth/auth.component').then((m) => m.AuthComponent),
    loadChildren: () =>
      import('./views/auth/auth.routes').then((r) => r.routes),
  },
  {
    path: '',
    loadComponent: () =>
      import('./views/page/page.component').then((m) => m.PageComponent),
    loadChildren: () =>
      import('./views/page/page.routes').then((r) => r.routes),
  },
];

import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { routes } from './app.routes';
import { tokenInterceptorService } from '@app/api/token-interceptor.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([tokenInterceptorService])),
    provideAnimationsAsync(),
    provideAngularSvgIcon(),
    {
      provide: MessageService,
    },
    {
      provide: CookieService,
    },
  ],
};

import { inject } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { Icon } from '@app/models/commons.model';
import { icons } from '@app/data/icons';

export abstract class CustomIconBase {
  private iconReg = inject(SvgIconRegistryService);

  protected constructor() {
    icons.forEach((icon: Icon) =>
      this.iconReg.loadSvg(icon.path, icon.name)?.subscribe(),
    );
  }
}

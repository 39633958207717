import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserStoreService } from '@app/services/user-store.service';

export function tokenInterceptorService(
  req: HttpRequest<any>,
  next: HttpHandlerFn,
) {
  const authToken = inject(UserStoreService).token;

  if (authToken) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }

  return next(req);
}
